// @flow
"use client";
import * as React from "react";
import { FC, CSSProperties } from "react";
import Dropdown from "react-bootstrap/esm/Dropdown";
import { QRLK } from "./QRLK";
import { User } from "@runetid/js-sdk";

type IBurgerMenuProps = {
  className?: string
  style?: CSSProperties
  user: User | null
};
export const BurgerMenu: FC<IBurgerMenuProps> = ({ user }) => {

  const logoutHandler = () => {
    fetch("/api/user/logout", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }).then(() => (window.location.href = "/"));
  };

  return (
    <div className="menu-wrapper">
      <Dropdown>
        <Dropdown.Toggle className="menu-toggle">
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {user &&
            <Dropdown.Item
              style={{ display: "flex", justifyContent: "center" }}> <QRLK
              user={user} /></Dropdown.Item>}
          {!user
            ? <>
              <Dropdown.Item href="/events"> Мероприятия</Dropdown.Item>
              <Dropdown.Item href="/about"> О сервисе</Dropdown.Item>
              <Dropdown.Item href="/auth/login"> Войти</Dropdown.Item>
            </>
            : <>
              <Dropdown.Item href="/events"> Мероприятия</Dropdown.Item>
              <Dropdown.Item href="/about"> О сервисе</Dropdown.Item>
              <Dropdown.Item href="/user/events/all">Личный
                кабинет</Dropdown.Item>
              <Dropdown.Item onClick={logoutHandler}>Выход</Dropdown.Item>
            </>
          }

        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
