'use client'

import {Button, Form} from "react-bootstrap";
import React from "react";
import {useForm} from "react-hook-form";

interface IFormInput {
    email: string
}

export default function SubscribeForm() {

    const {register, handleSubmit} = useForm<IFormInput>()
    const onSubmit = (data: IFormInput) => {
        fetch('/api/subscribe', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        }).then((resp: Response) => {
            if (resp.status === 200) {
                alert('Вы успешно подписались на рассылку')
            }
        })
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="form-wrap__input-wrap">
                <label>
                    <Form.Text className="form-wrap__title">Подпишитесь на нашу рассылку:</Form.Text>
                    <Form.Control
                        className="form-wrap__input"
                        type='email'
                        placeholder='Электронная почта'
                        {...register('email')}
                    />
                </label>
            </Form.Group>

            <Button variant="primary" type="submit" className="form-wrap__btn">
                Подписаться
            </Button>
        </Form>
    )
}