'use client'

import {QrButton} from "./qr/QrButton";
import {Button} from "react-bootstrap";
import authProvider from "../utils/authProvider";

export default function LogoutButton() {

    const handler = () => {
        fetch('/api/user/logout', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }).then(() => {
            window.location.href = '/'
        })
    }

    return (
        <>
            <QrButton/>
            <Button variant="outline-secondary" onClick={handler}>Выход</Button>
        </>
    )
}