// hooks/useWindowSize.js
import { useState, useEffect } from "react";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width: null | number
    height: null | number
  }>({
    width: null,
    height: null,
  });

  useEffect(() => {
    // Функция, которая обновляет размер окна
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Добавление обработчика события resize
    window.addEventListener("resize", handleResize);

    // Вызов функции handleResize для установки начального размера окна
    handleResize();

    // Удаление обработчика события при размонтировании компонента
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Пустой массив зависимостей означает, что этот эффект выполнится один раз при монтировании и размонтировании

  return windowSize;
}

export default useWindowSize;
