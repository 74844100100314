"use client";
// @flow
import * as React from "react";
import { FC, CSSProperties } from "react";
import { ButtonGroup, Col } from "react-bootstrap";
import Link from "next/link";
import { QRLK } from "../QRLK";
import { User } from "@runetid/js-sdk";

type ILkProps = {
  className?: string
  style?: CSSProperties
  user: User
};
export const Lk: FC<ILkProps> = ({ user }) => {

  return (
    <Col className="d-none d-sm-block d-sm-none d-xl-block" sm="auto">


      <ButtonGroup className="header__btnsGroup"
                   aria-label="Lc buttons"
                   style={{
                     display: "flex",
                     columnGap: 10,
                     padding: "10px 15px",
                     alignItems: "center",
                   }}>
        <QRLK user={user} />
        <span style={{
          fontSize: 25,
          lineHeight: 1,
          position: "relative",
          top: -3,
          color: "#b8b8b8",
        }}>|</span>
        <Link href="/user/events/all">
                <span>
                Личный кабинет
                </span>
        </Link>
      </ButtonGroup>


    </Col>
  );
};
