"use client";
import React, { useMemo } from "react";
import { usePathname } from "next/navigation";
import useWindowSize from "@/hooks/useWindowSize";

type NavObject = {
  name: string;
  path: string;
}

  ;

interface LinkItemProps {
  name: string;
  path: string;
}

const Navigation = () => {
  const { width } = useWindowSize();
  const pathname = usePathname();
  const nav: NavObject[] = useMemo(() => [
    {
      name: "Мероприятия",
      path: "/events",
    },{
      name: "О сервисе",
      path: "/about",
    },{
      name: "Контакты",
      path: "/contacts",
    },
  ], [pathname]);

  return (
    <>
      {width && width > 1200 ? <nav style={{ display: "flex", paddingLeft: 40 }}>
        <ul>
          {nav.map((item: LinkItemProps, index: number) => (
            <li key={`nav-${index}`}
                className={pathname === item.path ? "active" : ""}
            >
              <a href={item.path}>{item.name}</a>
            </li>
          ))}
        </ul>
      </nav> : <></>}
    </>

  );
};

export default Navigation;
